import { Helmet } from 'react-helmet';
import { useBrand } from '@cof/plastic-components';
import { withText } from '../../text/textStore';
import { TText } from '@cof/acq-text-ukweb';

type DocumentHeadProps = {
    Text: TText;
};

const DocumentHead = ({ Text: text }: DocumentHeadProps) => {
    const brand = useBrand();
    return (
        <Helmet>
            <link rel="icon" href={`/${brand}_favicon.ico`} />
            <title>{text({ path: 'Title' })}</title>
            {brand.toLowerCase() === 'asos' && <meta name="robots" content="noindex" />}
            <meta name="Description" content={text({ path: 'Description' })} />
        </Helmet>
    );
};

const DocumentHeadWithText = withText('DocumentHead')(DocumentHead);

DocumentHeadWithText.displayName = 'DocumentHead';

export default DocumentHeadWithText;
