import axios from 'axios';
import { useState, useEffect } from 'react';
import { useBrand } from '@cof/plastic-components';
import useConfig from '../useConfig/useConfig';
import { Config } from '../../../env/config';
import { addNewRelicError } from '../newRelic';
import { Dict, PathKey } from '@cof/ukweb-config/lib/clientConfig';

interface FetchFirstNameParams {
    baseURL: Dict;
    quotation?: string;
    token?: string;
    brand: string;
    channel?: string;
}

const fetchFirstName = async ({ baseURL, quotation, token, brand, channel }: FetchFirstNameParams) => {
    if (!quotation && !token) return '';
    const queryObject: { [key: string]: any } = { quotation, token, channel, partner: brand };
    Object.keys(queryObject).forEach((key) => queryObject[key] === undefined && delete queryObject[key]);
    const queryParams = new URLSearchParams(queryObject);

    try {
        if (typeof baseURL !== 'string') throw Error('Error baseURL not provided');
        const res = await axios.get(`${baseURL}?${queryParams.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const returnedFirstName = res?.data?.firstName;
        if (returnedFirstName) {
            return returnedFirstName;
        }
        return '';
    } catch (_) {
        addNewRelicError('axios-error', { clientTimestamp: Date.now(), sawError: true });
        return '';
    }
};

interface UseCustomerEndpointReturn {
    name: string | undefined;
    isLoading: boolean;
}

const useCustomerEndpoint = (
    configURL: PathKey<Config>,
    quotation?: string,
    token?: string,
    channel?: string
): UseCustomerEndpointReturn => {
    const [name, setName] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const brand = useBrand();

    const {
        values: [baseURL]
    } = useConfig([configURL]);

    useEffect(() => {
        fetchFirstName({ baseURL, quotation, token, brand, channel })
            .then(setName)
            .finally(() => {
                setIsLoading(false);
            });
    }, [baseURL, quotation, token, brand, channel]);

    return { name, isLoading };
};

export default useCustomerEndpoint;
