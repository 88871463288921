/* eslint-disable react/jsx-props-no-spreading */
import {
    BrandedLogo,
    CapitalOneLogoPrimaryLight,
    LittlewoodsLogoPrimary,
    LumaLogoPrimaryDark,
    OceanLogoPrimary,
    VeryLogoPrimary,
    PostOfficeLogoCore,
    ASOSLogoPrimary,
    BrandedLogoProps
} from '@cof/plastic-components';
//@ts-ignore - tsc can't find - need typings for svgLoader?
import ThinkMoneyCreditCardLogo from './ThinkMoney-CreditCard.svg?react';

const ThinkMoneyCreditCardLogoWithAlt = () => <ThinkMoneyCreditCardLogo aria-label="thinkmoney Credit Card" />;

const brandConfig = {
    capitalOne: CapitalOneLogoPrimaryLight,
    littlewoods: LittlewoodsLogoPrimary,
    luma: LumaLogoPrimaryDark,
    ocean: OceanLogoPrimary,
    very: VeryLogoPrimary,
    postoffice: PostOfficeLogoCore,
    asos: ASOSLogoPrimary,
    thinkmoney: ThinkMoneyCreditCardLogoWithAlt
};

// pass null for the allowList as this will cause it to pick up the allowList from the top level brand provider
//@ts-ignore - plastic to expand allowList to take string[] | undefined | null. `null` has a specific
//behaviour different to passing undefined, as mentioned in comment above
const Logo = (props: BrandedLogoProps) => <BrandedLogo brandConfig={brandConfig} allowList={null} {...props} />;

export default Logo;
