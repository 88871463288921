interface Pixel {
    complete?: boolean;
    addEventListener: (type: string, listener: () => void, options?: { once: boolean }) => void;
}

const loadPixel = (pixel: Pixel): Promise<void> =>
    new Promise((resolve) => {
        if (pixel.complete) {
            resolve();
        } else {
            pixel.addEventListener('load', () => resolve(), { once: true });
            pixel.addEventListener('error', () => resolve(), { once: true });
        }
    });

export default loadPixel;
