import createStore from '@cof/acq-text-ukweb';
import { TText } from '@cof/acq-text-ukweb';
import withTemplate from '@cof/acq-text-ukweb/lib/plugins/hocs/withTemplate';

const { withText, TextStore } = createStore<{ Text: TText }>({
    hocs: {
        TemplateText: withTemplate
    }
});

export { withText, TextStore };
