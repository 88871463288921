import React from 'react';
import { styled, css } from 'styled-components';

import { Flex, Header as PlasticHeader, useBrand } from '@cof/plastic-components';
import Logo from '../Logo';
// @ts-ignore
import FeefoPlatinumLogo from './Feefo-Platinum-Logo.svg';

import { shouldDisplayVerifyWithRetries } from '../../utils';

const FeefoAward = styled.img`
    ${({ theme: { breakpoints } }) => css`
        width: 140px;
        height: 42px;

        ${breakpoints.mq.tabletMin} {
            width: 207px;
            height: 32px;
        }
    `}
`;

export const LogoComponent = () => (
    <Flex width="100%" height="100%" justifyContent={['center', null, 'flex-start']} alignItems="center">
        <Logo />
    </Flex>
);

type VerifyWithRetriesLogoComponentProps = {
    isFeefoPlatinumAward: boolean;
};

export const VerifyWithRetriesLogoComponent = ({ isFeefoPlatinumAward }: VerifyWithRetriesLogoComponentProps) => (
    <Flex width="100%" height="100%" justifyContent={['space-around', null, 'space-between']}>
        <Logo width="170px" />
        {isFeefoPlatinumAward && (
            <FeefoAward src={FeefoPlatinumLogo} alt="Feefo 2024 Platinum Trusted Service Award Winner" />
        )}
    </Flex>
);

const StyledPlasticHeader = styled(PlasticHeader)`
    ${({ theme }) => theme.components.verify.appHeader};
    padding: 0;
`;

const Header = () => {
    const brand = useBrand();
    const isVerifyWithRetries = shouldDisplayVerifyWithRetries(brand);
    return (
        <StyledPlasticHeader bordered={false}>
            {isVerifyWithRetries ? (
                <VerifyWithRetriesLogoComponent isFeefoPlatinumAward={isVerifyWithRetries} />
            ) : (
                <LogoComponent />
            )}
        </StyledPlasticHeader>
    );
};

export default Header;
