import { useEffect } from 'react';
import ukwebconfig from '@cof/ukweb-config/lib/clientConfig';

export type ZukoTrackEvent = (arg: ZukoEvent | string) => ZukoInstance | undefined;
export type ZukoSetAttribute = (k: string, v?: string) => ZukoInstance | undefined;
export type ZukoTrackForm = (config: { slug: string; target: HTMLElement }) => ZukoInstance | undefined;

export interface ZukoInstance {
    trackEvent: ZukoTrackEvent;
    setAttribute: ZukoSetAttribute;
}

export interface ZukoEvent {
    type: string;
}

declare global {
    interface Window {
        initializeZuko: () => void;
        zukoAttributes?: {
            channel: string;
            brand: string;
            vendor?: string;
        };
        zukoInstance?: ZukoInstance;
        Zuko?: {
            trackForm: ZukoTrackForm;
            FORM_VIEW_EVENT: string;
            COMPLETION_EVENT: string;
        };
    }
}

window.initializeZuko = () => {
    const zukoConfig = ukwebconfig.get('zuko');
    if (zukoConfig.isEnabled && window.Zuko && window.zukoAttributes && !window.zukoInstance) {
        const zukoAttr = window.zukoAttributes;
        const brand = zukoAttr.brand;
        const altBrand = brand === 'ocean' || brand === 'thinkmoney';
        const slug = altBrand ? zukoConfig.slug[brand] : zukoConfig.slug.default;

        window.zukoInstance = window.Zuko.trackForm({ slug, target: document.body })
            ?.setAttribute('Channel', zukoAttr.channel)
            ?.setAttribute('Brand', zukoAttr.brand)
            ?.setAttribute('Vendor', zukoAttr.vendor)
            ?.trackEvent(window.Zuko.FORM_VIEW_EVENT);
    }
};

export const trackGenericEvent = (eventName: string | ZukoEvent) => {
    if (typeof window?.zukoInstance?.trackEvent === 'function') {
        try {
            window.zukoInstance.trackEvent(eventName);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }
};

// eslint-disable-next-line import/prefer-default-export
interface ZukoAttributes {
    channel: string;
    brand: string;
    vendor?: string;
}

export const useZuko = ({ channel, brand, vendor }: ZukoAttributes) => {
    useEffect(() => {
        // Validate the required attributes exist.
        if (channel && brand) {
            window.zukoAttributes = {
                channel,
                brand,
                vendor
            };
        }
        // Also called in GTM Zuko tag
        window.initializeZuko();
    }, [channel, brand, vendor]);

    const trackCompletionEvent = () => {
        if (window?.Zuko?.COMPLETION_EVENT) {
            trackGenericEvent(window.Zuko.COMPLETION_EVENT);
        }
    };

    const trackIncorrectKBA = () => {
        trackGenericEvent({ type: 'Error: Incorrect Date of Birth' });
    };

    const trackDoubleToken = () => {
        trackGenericEvent({ type: 'Error: Quotation and Short token provided' });
    };

    return { trackCompletionEvent, trackIncorrectKBA, trackDoubleToken };
};
