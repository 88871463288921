import { ALLOWED_BRANDS, Brand } from '../../utilities/constants';

const useBrandStrategy: () => Brand = () => {
    const { search, hostname } = window.location;

    if (hostname === '127.0.0.1' || hostname === 'localhost') {
        const query = new URLSearchParams(search);
        const p = query.get('partner');
        return narrow(p);
    }

    if (hostname.indexOf('-') > -1) {
        const brand = hostname.split('-')[0];
        return narrow(brand);
    }
    return 'capitalOne';
};

const narrow = (b: string | null) => ALLOWED_BRANDS.find((x) => x === b) || 'capitalOne';

export default useBrandStrategy;
