import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Flex,
    Heading,
    List,
    ListItem,
    PageRowFullHeight,
    Text as PlasticText,
    THEME_CONSTANTS as TC
} from '@cof/plastic-components';

import { useTheme } from 'styled-components';
import { withText } from '../../../../text/textStore';
import { ERROR_TYPE } from '../../../../utilities/constants';
import { TText } from '@cof/acq-text-ukweb';

type ServerErrorProps = {
    Text: TText;
};

const ServerError = ({ Text, Text: text }: ServerErrorProps) => {
    const theme = useTheme();
    return (
        <PageRowFullHeight wrapperBg={theme.components.verify.background} data-qa-id="tech-error-heading-page-row">
            <Heading
                data-qa-id="tech-error-heading"
                level="1"
                color={theme.components.verify.heading}
                textAlign={['center', null, 'center']}
            >
                <Text path="Title" />
            </Heading>
            <Box
                data-qa-id="tech-error-message"
                paddingY={['xs', 'sm', 'md']}
                maxWidth={TC.SCREEN_WIDTHS.SIX_COLUMN}
                mx="auto"
            >
                <PlasticText data-qa-id="tech-error-message-line1" color={theme.components.verify?.text}>
                    <Text path="List.Description" />
                </PlasticText>
                <List iconColor={theme.components.verify.iconColor}>
                    <ListItem>
                        <PlasticText data-qa-id="tech-error-message-line2" color={theme.components.verify?.listItem}>
                            <Text path="List.Item1" />
                        </PlasticText>
                    </ListItem>
                    <ListItem>
                        <PlasticText data-qa-id="tech-error-message-line3" color={theme.components.verify?.listItem}>
                            <Text path="List.Item2" />
                        </PlasticText>
                    </ListItem>
                </List>
                <PlasticText data-qa-id="tech-error-message-line4" color={theme.components.verify?.text}>
                    <Text path="Paragraph" />
                </PlasticText>
            </Box>
            <Flex justifyContent="center">
                <Button
                    data-qa-id="tech-error-message-button"
                    variant={theme.components.verify.buttonVariant}
                    as="a"
                    href={text({ path: 'Button.URL' })}
                >
                    <Text path="Button.Text" />
                </Button>
            </Flex>
        </PageRowFullHeight>
    );
};

const ServerErrorWithText = withText(`TechnicalError.${ERROR_TYPE.SERVER_ERROR}`)(ServerError);

ServerErrorWithText.displayName = 'ServerError';

export default ServerErrorWithText;
