import littlewoods from './littlewoods';
import capitalOne from './capitalOne';
import luma from './luma';
import ocean from './ocean';
import postoffice from './postoffice';
import very from './very';
import asos from './asos';
import thinkmoney from './thinkmoney';

export default { capitalOne, littlewoods, luma, ocean, postoffice, very, asos, thinkmoney };
