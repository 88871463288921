import { Component, ReactNode } from 'react';

import ErrorContext from '../../utilities/errorContext';
import { addNewRelicError } from '../../utilities/newRelic';

type ErrorBoundaryProps = { renderOnError: ReactNode; children: ReactNode };
type ErrorBoundaryState = { hasError: boolean };

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error: unknown) {
        if (error instanceof Error) addNewRelicError(error, { clientTimestamp: Date.now(), sawError: true });
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;
        const { renderOnError, children } = this.props;

        if (hasError) {
            return renderOnError;
        }

        return (
            <ErrorContext.Provider
                value={{
                    setError: () => {
                        this.setState({ hasError: true });
                    }
                }}
                data-qa-id="error-context-provider"
            >
                {children}
            </ErrorContext.Provider>
        );
    }
}

export default ErrorBoundary;
