import { PIXEL_LOAD_TIMEOUT } from '../constants';
import loadAllPixels from './loadAllPixels';

const waitForPixels = async () => {
    let timeoutOccurred = false;

    const timeoutPromise = new Promise<void>((resolve) => {
        setTimeout(() => {
            timeoutOccurred = true;
            resolve();
        }, PIXEL_LOAD_TIMEOUT);
    });

    await Promise.race([timeoutPromise, loadAllPixels()]);

    return { timeoutOccurred };
};

export default waitForPixels;
